@charset "utf-8";

/**
@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@400;500;700&family=IBM+Plex+Serif:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@400;500;700&family=IBM+Plex+Sans+Condensed:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

*/
@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Cormorant+SC:wght@400;500;600;700&display=swap');


// Color for green background for readability #005C4D
$castleton-green: #005C4D;
$darker-green: #00483d;
$white: #FEFFFE;
$black: #000000;
$very-dark-gray: #363636;
$slate-gray: #818D92;
$prussian-blue: #012438;
$lemon-curry: #CBA52C;
$flax: #E2C979;
$light-gray: #ececec;

$primary: $castleton-green;
$secondary: $lemon-curry;
$title-color: $white;
$subtitle-color: $white;
$has-text-white: $white;

$family-primary: 'Amiri', serif, Sans-Serif;
$family-secondary: 'Cormorant SC', serif;
$family-sans-serif: Sans-Serif; 

$body-font-size: 1.20rem;


.hero-img-med {
  @extend .hero;
  background: 
  /* top, transparent red */ 
  linear-gradient(141deg,
    rgba(0,92,77, .95), 
    rgba(11,66,60, .8)
  ),
  /* bottom, image */
  url(https://res.cloudinary.com/daq9dkhqd/image/upload/v1596617144/VLSH_leaf_003.5_gmmahv.webp);
  background-position: center;
  background-size: cover;
}

// Import only what you need from Bulma
@import "./../../../../node_modules/bulma/sass/utilities/_all.sass";
@import "./../../../../node_modules/bulma/sass/base/_all.sass";
@import "./../../../../node_modules/bulma/sass/components/_all.sass";
@import "./../../../../node_modules/bulma/sass/elements/_all.sass";
@import "./../../../../node_modules/bulma/sass/form/_all.sass";
@import "./../../../../node_modules/bulma/sass/grid/_all.sass";
@import "./../../../../node_modules/bulma/sass/helpers/_all.sass";
@import "./../../../../node_modules/bulma/sass/layout/_all.sass";