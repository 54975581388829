/* SCSS overides for bulma */
.bg-clinic {
  background-color: $light-gray;
}

.bg-holder {
  background-color: #006151;
  opacity: 1;
}

.hero-text {
  font-size: 5em;
}

.hero-text span {
  display: block;
  font-size: .75em;
}

.image-container {
  position: relative;
  width: 12.75em;
  height: 12.75em;
  line-height: 12.75em;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.resize-fit-center {
  position: absolute;
  margin-top: 5.5em;
  max-width: 100%;
  max-height: 100%;
}


.officeAddress {
  display: block;
  font-size: .75em;
}

.intakeLineHours {
  display: block;
  font-size: .75em;
}


.bd-notice {
  border: solid 1px #000;
  border-radius: 24px;
  color: #000;
  margin: 10px;
  padding: 1em;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 10px 40px
}

.bd-notice-img {
  max-width: 275px;
  order: 1;
}

.bd-notice-text {
  order: 2;
}

.bd-notice-title {
  font-size: 2.75rem;
  font-weight: bold;
  text-decoration-line: underline;
}

.bd-notice-subtitle {
  font-size: 1.25rem;
  
}

.event-title {
  font-size: 2.75rem;
  font-weight: bold;
}

.event-subtitle {
  margin-top: -1.0rem;
  font-size: 1.75rem;
}

.page-title {
  background-image: linear-gradient(to left,$castleton-green, $darker-green);
}

.hop-program-title {
  border: solid 2px $castleton-green;
  border-radius: 24px;
  color: #000;
}


.navbar-item img {
  max-height: 3.75rem;
}
/*
.bd-footer-info {
  color: $white;
}
*/
.bd-footer-links {
  margin-top: 6rem;
  margin-bottom: 6rem;

  & a {
    color: currentColor;
    &:hover {
      color: #3273dc;
    }
  }
}

.bd-footer-link-title {
  color: #363636;
  font-size: 1.25rem;
  font-weight: 600;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }
}

.bd-footer-link {
  margin-top: .5rem;
}

.bd-is-more {
  font-size: .875rem;

  & a:not(:hover) {
    color: #545454;
  }
}

.volunteer-form {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.reveal-if-active {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  font-size: 16px;
  transform: scale(0.8);
  transition: 0.5s;
  
  input[type="checkbox"]:checked ~ & {
    opacity: 1;
    max-height: 200px;
    padding: 10px 20px;
    transform: scale(1);
    overflow: visible;
  }
  
}

.aboutcontent {
  &__image {
    width: 10rem;
    float: left;
    margin-right: 1rem;
  }
  &__text {
    display: inline;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  margin: 0;
}

.wrapper > div {
  display: flex;
  flex-direction: column;
  flex: 1 0 325px;
  width: 250px;
  height: auto;
  margin: 10px 5px 10px 5px;
  padding: 1rem;
  border-radius: 6px;
  background-color: $prussian-blue;
}

.filler {
  background: linear-gradient(#012438, #818D92);
}

.eleventy-plugin-vimeo-embed {
  margin-bottom: 2.5rem;
}

.callout-link {
  margin-top: auto;
  color: #DABC58;

  &:visited {
    color: $lemon-curry;
  }

  &:hover {
    color: $flax;
    text-decoration: underline;
  }

  &:active {
    color: #D3AE36;
  }
}
.post-header-wrapper {
  margin-bottom: 3.75rem;
}

.post-info {
  font-size: 1.1rem;
}

.bm--card-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bm--card-equal-height .cta {
  margin-top: auto;
  padding: 15px 55px 25px 55px;
}


.bm--card-equal-height .card-footer {
  margin-top: auto;
}



.resourceBlock {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.resourceItem {
  margin-left:.5rem;
}


.resourceLink {
  cursor: pointer;
  font-size: 1.35rem;
  color: #394c9f !important;
  text-decoration: underline dotted;
  text-decoration-color: #394c9f;
}

.resourceLink:hover {
  text-decoration: underline solid;
}

.resourceText {
  font-size: 1rem;
  color: gray;
  margin-top: -10px;
}

.eleventy-plugin-embed-instagram {
  width: 100%;
}

.blue-block {
  margin: 10px 5px 10px 5px;
  padding: 1rem;
  border-radius: 6px;
  background-color: $prussian-blue;
}

.career-block-title {
  display: flex;
  flex-direction: row;
}


@media only screen and (min-width: 1023px) {
  a.navbar-item[aria-current=page] {
    border: 2px solid $primary;
  }
}

@media only screen and (max-width: 480px) {
  .hero-text {
    font-size: 2.2em;
  }

  .wrapper > * {
    margin: 10px 0px 10px 0px;
  }

  .bd-notice {
    flex-flow: column wrap;
    align-items: center;
    gap: 0px 0px;
  }

  .bd-notice-img {
    order: 1;
  }

  .bd-notice-text {
    order: 2;
  }

  .bd-notice-title {
    font-size: 2rem;
  }

  .bd-notice-subtitle {
    font-size: 1rem;
  }
  
}